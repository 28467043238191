import React, { useState, useEffect } from "react";
import { Link as ScrollLink } from 'react-scroll';
import tw from "twin.macro";
import styled, { keyframes } from 'styled-components';
import { css } from "styled-components/macro"; //eslint-disable-line
import flags from "../../images/Button.svg";
import skyBackground from "../../images/laptop_sky.png";
import skyBackgroundCell from "../../images/cellphone_sky.png";
import mascots from "../../images/Mascots.png";
import { Profile } from "helpers/Auth.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter";

const StyledHeader = styled(Header)`
  ${tw`p-4 max-w-none mx-0 w-full bg-primary-900 mb-12`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const ProfileButton = tw(ControlButton)`m-2 flex items-center outline p-2`;
const Container = styled.div`
  ${tw`relative -mx-8 -my-8 bg-center bg-cover h-screen min-h-144`}
  background-image: url(${props => props.background});
`;

// const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-0`
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-0`

// const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const HeroContainer = tw(Container)`z-20 mx-auto min-h-full flex flex-col overflow-auto sm:overflow-hidden`;
const Illustration = tw.img`w-64 mx-auto`;
const Content = tw.div`px-4 pb-8 sm:mt-12 flex flex-1 flex-col justify-center sm:justify-normal items-center `;
const Grid = tw.div`grid grid-cols-1 sm:grid-cols-2 gap-4`;
// const Grid2 = tw.div`grid grid-cols-1 gap-y-0`;
const HeaderContainer = styled.div`
  display: flex-column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const Heading = styled.h1`
  ${tw`text-5xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug mb-4`}
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.9);
  span {
    ${tw`inline-block mt-2`}
   }
`;
const Word = tw.p`text-primary-100`;

const PrimaryAction = tw.a`
  rounded-full px-8 py-3 text-sm sm:text-base sm:mt-2 sm:px-8 sm:py-4 bg-gray-100 
  font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 
  focus:outline-none focus:shadow-outline text-center
`;

const PrimaryAction2 = tw.a`
  rounded-full px-12 py-3 mt-4 mb-16 sm:mt-5 text-sm sm:text-base sm:px-12 sm:py-4 bg-gray-100 
  font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 
  focus:outline-none focus:shadow-outline text-center justify-center col-span-2
`;

const heartbeat = keyframes`
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.99);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.99);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
`;

const UnitedGradCompetitionButton = styled(PrimaryAction)`
  background-image: url(${flags});
  background-size: cover;
  position: relative;
  overflow: hidden;
  animation: ${heartbeat} 2s infinite;

  ::before, ::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    transform: rotate(0deg);
  }

  ::after {
    animation-delay: 1s; // delay the second spotlight by 1s to create two spotlights
  }
`;

export default ({
  username = "Profile",
  navs = [],
  navLinks = [
    <NavLinks key={1}>
      {navs && navs.includes("Administration and General Affairs") ?
        <NavLink href="/wod-auth/admin-and-general-affairs">
          Administration and General Affairs
        </NavLink> : ""
      }
      {navs && navs.includes("Ministry of Theology") ?
        <NavLink href="/wod-auth/ministry-of-theology">
          Ministry of Theology
        </NavLink> : ""
      }
      {navs && navs.includes("Internal Affairs") ?
        <NavLink href="/wod-auth/internal-affairs">
          Internal Affairs
        </NavLink> : ""
      }
      <NavLink></NavLink>
      <NavLink></NavLink>
      <NavLink></NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <ProfileButton onClick={Profile}>
        <UserIcon tw="w-6 h-6 mr-2" /> {username}
      </ProfileButton>
    </NavLinks>
  ],
  cta = [
    { header: "Login to LightRainAir", route: "/login" },
    { header: "Register to LightRainAir", route: "/register" }
  ],
  bgImage = (window.innerWidth >= 640) ? skyBackground : skyBackgroundCell

}) => {

  return (
    <Container background={bgImage}>
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <Content>
          {(cta.length == 2) ? <>
            <HeaderContainer>
              <Illustration src={mascots} />
              <Heading>Welcome to LightRainAir</Heading>
            </HeaderContainer> </> : ""
          }
          <Grid>
            {cta.map((call, index) => {
              // if (index === cta.length - 2) {
                // return (
                  // <UnitedGradCompetitionButton
                    // key={index}
                    // href={call.route}
                  // >
                    // {call.header}
                  // </UnitedGradCompetitionButton>
                // );
              // } else if (index == cta.length - 1) {
                // return null
              // } else {
                return (
                  <PrimaryAction
                    key={index}
                    href={call.route}
                    css={index === 1 && call.route === "#" ? `color: gray; cursor: default; hover: none` : ``}
                  >
                    {call.header}
                  </PrimaryAction> 
                );
              // }
            })}
          </Grid>
          {/* <Grid> */}
              {/* <PrimaryAction2 href={cta[8].route}>{cta[8].header}</PrimaryAction2> */}
            {/* </Grid> */}
        </Content>
        <MiniCenteredFooter tw="mb-8" />
      </HeroContainer>
    </Container>
  );
};